import {get, postJ} from "@/request";
import {IPageRes} from "@/apis/page";
import {IEnjoyPromoterList, IEnjoyPromoterListParam} from "@/apis/enjoyPromoter/list/types";

/**
 * 推广员分页查询
 * @param params 查询参数
 */
export const enjoyPromoterQueryApi = (params: IEnjoyPromoterListParam) => get<IPageRes<IEnjoyPromoterList[]>>("/admin/miniPromoter/query", params)
/**
 * 创建
 * @param data
 */
export const enjoyPromoterCreateApi = (data: Partial<IEnjoyPromoterList>) => postJ("/admin/miniPromoter/create", data)
/**
 * 修改
 * @param data
 */
export const enjoyPromoterModifyApi = (data: Partial<IEnjoyPromoterList>) => postJ("/admin/miniPromoter/modify", data)
/**
 * 查询详情
 * @param id
 */
export const enjoyPromoterDetailApi = (id: string) => get<IEnjoyPromoterList>("/admin/miniPromoter/detail/" + id)
